const home = {
  title: "Scottish Tour Guide | Marion Rodger",
  description:
    "Explore Scotland with your professional Scottish Tourist Guide. As a qualified tour guide and member of the STGA I guide and accompany groups or individuals throughout Scotland and can help you plan your holiday.",
  h1: "Ceud mile failte – 100,000 Welcomes!",
  h2: "Explore Scotland with your professional Scottish Tourist Guide.",
  para1:
    "Scotland is a stunningly beautiful country at any time of the year with a rich cultural heritage and eventful history.",
  para2:
    "As a qualified tour guide and member of the STGA I guide and accompany groups or individuals throughout Scotland and can help you plan your holiday.",
  para3Question:
    "With convenient direct links to many European cities why not take a city break in Scotland?",
  para3:
    "Discover the Jekyll and Hyde nature of Edinburgh with the labyrinth of closes in the Old Town and the elegant Georgian architecture of the New Town or take a tour around Glasgow, a modern, vibrant metropolis with world class museums and galleries.",
  para4Question: "Prefer to get out of the city?",
  para4:
    "Take a day tour – to Stirling, St Andrews or the Borders, for example. Visit a castle or stately home and sample “the Water of life” in a whisky distillery.",
  para5Question: "Like active holidays?",
  para5:
    "I can recommend the best places to walk, cycle, run and play golf in Scotland.",
  para6Question: "Love gardens?",
  para6:
    "Relax on a garden tour – see palms growing on Scotland’s west coast, forests of Rhododendrons, lush herbaceous borders or spectacular autumn tree colour",
  para7Question: "Want to recharge your batteries?",
  para7:
    "Take a guided tour to the Highlands where the pace of life is slower, drink in the breathtaking beauty of the rugged landscape, enjoy the fresh air and wildlife, see some of Scotland’s ancient monuments, learn more about our history and traditions.",
  para8Question: "Need to escape completely?",
  para8:
    "Let’s head off to one of Scotland’s islands to get away from it all or let me show you some of the hidden treasures on the Mainland.",
  closingPara:
    "Let me take you on an unforgettable journey through our fascinating country!",
  farewell: "See you in Scotland!",
};

export default home;
