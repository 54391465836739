const contactForm = {
    NameLabel: "Name",
    EmailLabel: "E-Mail-Addresse",
    PhoneLabel: "Telefonnummer",
    CommentsLabel: "Weitere Kommentare",
    successResponse: "Anfrage geschickt",
    failedResponse: "Anfrage konnte nicht gesendet werden",
    validationEmail: "Ungültige E-Mail-Adresse",
    validationName: "Name erforderlich",
    validationPhone: "Ungültige Telefonnummer",
    submitButton: "Anfrage senden",
  };
  
  export default contactForm;
  