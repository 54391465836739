const SiteLoading = () => {
  return (
    <div className="navBar">
      <div className="navBar-contents">
        <img src="STGALogo.svg" className="navBar-icon" />
        <h1
          style={{
            color: "white",
            fontSize: "30px",
            margin: "0px",
            fontWeight: "normal",
          }}
        >
          Marion Rodger
          <small style={{ display: "block", fontSize: "15px" }}>
            Scottish Tour Guide
          </small>
        </h1>
      </div>
    </div>
  );
};

export default SiteLoading;
