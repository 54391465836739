import { useLocation } from "react-router-dom";
import routes from "../data/routes";

export const useCurrentRoute = () => {
  const { isCurrent } = useCurrentRouteCheck();
  return useAllRoutes().find((r) => isCurrent(r));
};

const useCurrentRouteCheck = () => {
  const location = useLocation();

  const isCurrent = (route) => {
    return (
      location.pathname.toLocaleLowerCase() === route.path.toLocaleLowerCase()
    );
  };

  return { isCurrent };
};

export const useAllRoutes = () => {
  const switchRoutes = [];
  for (var i = 0; i < routes.length; ++i) {
    switchRoutes.push(routes[i]);
    for (var j = 0; j < routes[i].subRoutes?.length ?? 0; ++j) {
      const sub = routes[i].subRoutes[j];
      switchRoutes.push(sub);
    }
  }
  return switchRoutes;
};

export const useRoutes = () => {
  const { isCurrent } = useCurrentRouteCheck();
  return routes.map((r) => {
    let subs;
    if(r.subRoutes)
    {
      subs = r.subRoutes.map(sr => isCurrent(sr) ? { ...sr, isCurrent: true } : sr);
    }
    
    return isCurrent(r) || subs?.some(r => r.isCurrent) ? { ...r, isCurrent: true, subRoutes: subs } : { ...r, subRoutes: subs };
  });
};
