const about = {
  title: "Experienced Scottish Tour Guide | Quality Bespoke Tours",
  description:
    "An experienced member of the Scottish Tourist Guides Association I am a fully qualified, freelance tour guide for Scotland.",
  h1: "About",
  para1:
    "While I was born “south of the border” I have lived in Scotland for 30 years and am a passionate ambassador for my adopted country. I still enjoy travelling around Scotland – with groups on a coach tour or by private car, island hopping by bicycle with my family or running against the elements in half marathons.",
  para2:
    "As an experienced member of the Scottish Tourist Guides Association I am a fully qualified, freelance tour guide for Scotland",
  h2: "Qualifications and experience",
  li1:
    "Heriot-Watt University, Edinburgh - BA (honours) in French & German (Translating and Interpreting)",
  li2:
    "University of Dundee/Open University – Modern Scottish History from 1707",
  li3: "Scottish Agricultural College - HND Garden Design",
  li4: "RHS Certificate in Horticulture",
  li5: "Emergency First Aid at Work",
  li7: "STGA accreditation for tours in the {link}",
  li7linkText: "Scottish Parliament",
  li7linkTitle: "The Scottish Parliament website.",
  li8:
    "STGA accreditation for {link} Sporting Heritage Tours (coach & walking)",
  li8linkText: "G:2014",
  li8linkTitle: "Glasgow Commonwealth Games 2014",
  li9: "Volunteer Education Guide with the ",
  li9linkText: "National Trust for Scotland",
  li9linkTitle: "The National Trust for Scotland website.",
  li10: "On board train host Belmond Royal Scotsman",
  li10linkText: "Belmond Royal Scotsman",
  li10linkTitle: "The Belmond Royal Scotsman train journey website.",
  PrestonMillAltText: "Edinburgh & the Lothians, Preston Mill.",
};

export default about;
