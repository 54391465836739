const carousel = {
  home: [
    "A Harris hawk taken at a falconry display in scotland.",
    "Plants from the Ascog Hall Gardens and Victorian Fernery",
    "The Paps of Jura mountains.",
  ],
  about: [
    "Bicycle with a view of the hebrides",
    "The Lismore lighthouse built in 1833 by Robert Stevenson",
    "The Forth Bridge Scotland",
  ],
  services: [
    "The harbour of Tarbert off of Loch Fyne.",
    "The Abbey of Saint Mary of Crossraguel ruins",
    "Cormorant on rocks on the Isle of Lewis",
  ],
  escape: ["Boat travelling down Loch Katrine"], //this is highlands page.
  edinburgh: ["Edinburgh Castle"],
  glasgow: ["Doulton fountain in Glasgow"],
  contact: ["View of Culzean Castle"],
};

export default carousel;
