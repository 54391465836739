const about = {
  title: "Über mich – Reiseleitung in Schottland",
  description:
    "Als erfahrenes Mitglied der Scottish Tourist Guides Association bin ich ein voll qualifizierter, freiberuflicher Reiseleiter für Schottland.",
  h1: "Über mich",
  para1:
    "Obgleich ich “südlich der Grenze” geboren bin, wohne ich seit 30 Jahren in Schottland und bin eine begeisterte Botschafterin für mein adoptiertes Land. Ich reise immer noch gerne in Schottland – ob mit einer Reisegruppe oder mit dem PKW, auf einem Familienurlaub mit dem Rad über die Insel oder beim Lauf eines Halbmarathons, gegen das stürmische Wetter.",
  para2:
    "Als erfahrenes Mitglied des Scottish Tourist Guides Association, bin ich eine voll qualifizierte, selbstständige Reiseleiterin für ganz Schottland.",
  h2: "Qualifikationen und Erfahrung",
  li1:
    "Heriot-Watt University, Edinburgh - BA (honours) in French & German (Translating and Interpreting)",
  li2:
    "University of Dundee/Open University – Modern Scottish History from 1707",
  li3: "Scottish Agricultural College - HND Garden Design",
  li4: "RHS Certificate in Horticulture",
  li5: "Emergency First Aid at Work",
  li7: "STGA accreditation for tours in the {link}",
  li7linkText: "Scottish Parliament",
  li7linkTitle: "The Scottish Parliament website.",
  li8:
    "STGA accreditation for {link} Sporting Heritage Tours (coach & walking)",
  li8linkText: "G:2014",
  li8linkTitle: "Glasgow Commonwealth Games 2014",
  li9: "Volunteer Education Guide with the ",
  li9linkText: "National Trust for Scotland",
  li9linkTitle: "The National Trust for Scotland website.",
  li10: "On board train host Belmond Royal Scotsman",
  li10linkText: "Belmond Royal Scotsman",
  li10linkTitle: "The Belmond Royal Scotsman train journey website.",
  PrestonMillAltText: "Edinburgh & das Lothians, Preston Mill.",
};

export default about;
