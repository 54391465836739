const home = {
  title: "Startseite – schottische Reiseleitung",
  description:
    "Entdecken Sie Schottland mit Ihrem professionellen schottischen Reiseführer. Als qualifizierter Reiseleiter und Mitglied der STGA führe und begleite ich Gruppen oder Einzelpersonen in ganz Schottland und kann Ihnen bei der Planung Ihres Urlaubs helfen.",
  h1: "Ceud mile failte – 100,000 Willkommen!",
  h2:
    "Schottland erleben - mit Ihrer professionellen schottischen Reiseleiterin",
  para1:
    "Schottland ist zu jeder Jahreszeit ein traumhaft schönes Land, mit reicher Kultur und ereignisreicher Geschichte.",
  para2:
    "Als qualifizierte Gästeführerin und Mitglied des STGA begleite und führe ich Gruppen oder Individualreisende durch ganz Schottland und kann Ihnen mit der Planung Ihres Urlaubs behilflich sein.",
  para3Question:
    "Mit Direktverbindungen zu vielen europäischen Städten warum machen Sie nicht einen Städteaufenthalt in Schottland?",
  para3:
    "Endecken Sie den Jekyll und Hyde Charakter von Edinburgh mit den zahllosen Gassen der Altstadt und eleganter, georgianischen Architektur der Neustadt oder machen Sie eine Stadtrundfahrt in Glasgow, einer modernen, lebendigen Metropole mit Museen und Gallerien, die zur Weltklasse gehören.",
  para4Question: "Lieber von der Stadt wegfahren?",
  para4:
    "Buchen Sie eine Tagestour – zum Beispiel Richtung Stirling, St. Andrews oder zum Grenzland. Sie können ein Schloß oder Herrenhaus besuchen und das „Lebenswasser“ bei einer Whiskybrennerei verkosten.",
  para5Question: "Sind Sie eher sportlich?",
  para5:
    "Ich zeige Ihnen, wo Sie am besten wandern, Rad fahren, laufen oder Golf spielen können.",
  para6Question: "Lieben Sie Gärten?",
  para6:
    "Entspannen Sie sich auf einer Gartenreise – bewundern Sie die Palmenarten, die an der Westküste Schottlands gedeihen, die blühenden Rhododendren, üppigen Staudenrabatten oder spektakulären Farben im Herbst.",
  para7Question: "Möchten Sie neue Kräfte auftanken?",
  para7:
    "Machen Sie eine Rundreise durch die Highlands, wo das Leben nicht so hektisch ist und saugen Sie die wilde Schönheit der Landschaft ein. Genießen Sie die frische Luft, die Tierwelt und besichtigen Sie Schottlands historische Denkmäler. Sie lernen dabei viel Interessantes über unsere Geschichte und Traditionen.",
  para8Question: "Flucht vor dem Alltag?",
  para8:
    "Dann fahren wir zu den ruhigen, schottischen Inseln oder suchen einige der verborgenen Schätze auf dem Festland auf.",
  closingPara:
    "Lassen Sie sich von mir, auf einer unvergesslichen Reise durch unser faszinierendes Land, begleiten!",
  farewell: "Wir sehen uns in Schottland!",
};

export default home;
