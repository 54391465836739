const edinburgh = {
  title: "Edinburgh Tour Guide | Custom Tours in Edinburgh",
  description:
  "Edinburgh, Scotland’s capital city and financial centre is a city for all seasons, world famous for its festivals, cultural heritage and incredible restaurants.",
  h1: "Tour Edinburgh",
  para1:
    "Edinburgh, Scotland’s capital city and financial centre is a city for all seasons, world famous for its festivals.",
  para2:
    "The Old and New Towns of Edinburgh are on UNESCO’s World Cultural Heritage list and while exploring the many visitor attractions in the city you will follow closely in the footsteps of some of Scotland’s most famous past and present literary figures e.g. Sir Walter Scott, Robert Burns, Robert Louis Stevenson, Sir Arthur Conon Doyle, Ian Rankin or JK Rowling.",
  para3:
    "Take a stroll down the Royal Mile from the {link} to {link}, visit our 21st century {link}, see the latest exhibition in the {link}, tour the {link} or climb the Arthur’s Seat volcano. At the end of the day dine in one of Edinburgh’s first class restaurants or chill out in a typical pub.",
  links: [
    { text: "Castle", title: "Edinburgh castle website" },
    {
      text: "Palace of Holyroodhouse",
      title: "The Palace of Holyroodhouse website",
    },
    { text: "Scottish Parliament", title: "The Scottish Parliament website" },
    { text: "National Galleries", title: "The National Galleries website" },
    { text: "Royal Yacht Britannia", title: "The Royal Yacht Britannia website" },
  ],
  reviews: [
    {
      text:
        "Dear Marion, thanks to you and Emma we had marvellous days in Scotland. The tour gave us a good idea what your country has to offer.",
      by: "German Group",
    },
  ],
  HolyroodhouseAltText: "Street Light at the palace of Holyroodhouse.",
};

export default edinburgh;
