import React from "react";

const LoadingContext = React.createContext({
  isLoading: false,
  setIsLoading: () => {},
});

export const useLoadingContext = () => React.useContext(LoadingContext);

const LoadingContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { Provider } = LoadingContext;

  return <Provider value={{ isLoading, setIsLoading }}>{children}</Provider>;
};

export default LoadingContextProvider;
