const contactForm = {
    NameLabel: "Full Name",
    EmailLabel: "Email Address",
    PhoneLabel: "Phone Number",
    CommentsLabel: "Further Comments",
    successResponse: "Request sent",
    failedResponse: "Could not send request",
    validationEmail: "Invalid email address",
    validationName: "Name Required",
    validationPhone: "Invalid Phone Number",
    submitButton: "Send Request",
  };
  
  export default contactForm;
  