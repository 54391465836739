import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools"

const breakpoints = createBreakpoints({
  xs: "320px",
  sm: "540px",
  md: "768px",
  lg: "960px",
  xl: "1300px",
});

const theme = extendTheme({
  components: {
    Text: {
      baseStyle: {
        color: "#666",
      },
      variants: {
        brand: {
          color: "white",
        },
      },
    },
    Heading: {
      baseStyle: {
        color: "#666",
      },
      variants: {
        brand: {
          color: "white",
        },
      },
    },
  },
  breakpoints
});

export default theme;
