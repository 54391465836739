const navigation = {
    home: "Startseite",
    homeTitle: "Willkommen – Marion Rodger, schottische Reiseleitung",
    about: "Über mich",
    aboutTitle: "Ihre Reiseleiterin in Schottland",
    services: "Leistungen",
    servicesTitle: "Leistungen",
    escape: "Schottland",
    escapeTitle: "Reiseziel Schottland",
    highlands: "Highlands und Inseln",
    highlandsTitle: "Besuchen Sie die Highlands und die Inseln.",
    edinburgh: "Edinburgh",
    edinburghTitle: "Besuchen Sie Edinburgh.",
    glasgow: "Glasgow",
    glasgowTitle: "Besuchen Sie Glasgow.",
    contact: "Kontakt",
    contactTitle: "Nehmen Sie Kontakt auf.",
}

export default navigation;
