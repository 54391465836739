const services = {
    title: "Leistungen – Tour Services",
    description:
    "Mit einer Reihe von Dienstleistungen kann ich mit Ihrer eigenen Reiseroute arbeiten oder eine Tour maßschneidern, die Ihren Interessen und Ihrer Zeit entspricht.",
    h1: "Leistungen",
    para1: "Ich kann mit Ihrem eigenen Reiseplan bzw. Programm arbeiten oder eine maßgeschneiderte Tour, je nach individuellen Interessen und Zeitplanungen exklusiv für Sie zusammenstellen. Für weitere Informationen setzen Sie sich bitte mit mir in Verbindung.",
    list: [
      "Stadtführung zu Fuß",
      "Stadtbesichtigung im Reisebus oder Minibus",
      "Tagesausflüge",
      "Mehrtägige Reisen und Rundreisen",
      "Reiseplanung",
      "Reisen zu einem besonderen Thema – z. B. Agrartouren, Whiskyreisen",
      "Führungen für Rollstuhlfahrer",
      "Gartenreisen",
      "Private Touren zu Fuß oder in einem Mietwagen",
      "Landausflüge für Kreuzfahrten",
      "Corporate und Incentive",
      "HospiDesk für Tagungen und Konferenzen",
      "Ahnenforschung",
      "Schule- und Studentengruppen"
    ],
    StirlingCastleAltText: "Einhorn Statue bei Stirling Castle.",
    reviews: [{ text: "Dear Marion, you made us fall in love with Scotland!  Thank you for all your insight and comments that made our trip so memorable", by: "US-Gruppe"}]
  };
  
  export default services;
  