const carousel = {
  home: [
    "Ein Harris-Falke, der bei einer Falknereiausstellung in Schottland genommen wurde.",
    "Pflanzen aus den Ascog Hall Gardens und Victorian Fernery.",
    "Die Paps of Jura Berge.",
  ],
  about: [
    "Fahrrad mit Blick auf die Hebriden",
    "Der Leuchtturm von Lismore wurde 1833 von Robert Stevenson erbaut",
    "Die Forth Bridge Schottland",
  ],
  services: [
    "Der Hafen von Tarbert vor Loch Fyne.",
    "Die Abtei der Heiligen Maria von Crossraguel Ruinen",
    "Kormoran auf Felsen auf der Isle of Lewis",
  ],
  escape: ["Boot fährt Loch Katrine hinunter"], //this is highlands page.
  edinburgh: ["Edinburgh Schloss"],
  glasgow: ["Doulton Brunnen in Glasgow"],
  contact: ["Blick auf Culzean Castle"],
};

export default carousel;
