const services = {
    title: "Scottish Tour Services | Custom Tours Designed By You",
    description:
    "With a range of service, I can work with your own itinerary or tailor make a tour to suit your interests and time.",
    h1: "Services",
    para1: "I can work with your own itinerary or tailor make a tour to suit your interests and time available. Please contact me for more details.",
    list: [
      "Walking tours",
      "Half day city tours by coach or minibus",
      "Full day tours",
      "Multi-day and extended tours",
      "Assistance with tour planning and routes",
      "Guiding for specialist groups - e.g. farm tours, whisky tours",
      "Tours for wheelchair users",
      "Garden and Horticultural tours",
      "Driver guiding",
      "Shore excursions for cruise ships",
      "Corporate and incentive guiding",
      "Hospitality desk for conferences",
      "Researching your Scottish ancestry",
      "School and student groups"
    ],
    StirlingCastleAltText: "Unicorn statue at Stirling Castle.",
    reviews: [{ text: "Dear Marion, You made us fall in love with Scotland! Thank you for all your insight and comments that made our trip so memorable.", by: ""}]
};
  
  export default services;