const highlands = {
  title: "Reisen – Reisen in die Highlands",
  description:
  "Das schottische Hochland und die schottischen Inseln erstrecken sich über ein weites, dünn besiedeltes Gebiet mit vielfältigen Landschaften, von mit Heidekraut bedeckten Bergen und einsamen Mooren bis hin zu Kiefernwäldern und romantischen Seen (lochs) und Tälern (glens).",
  h1: "Highlands und Inseln",
  para1:
    "Die Highlands und schottischen Inseln umfassen ein riesiges, sehr dünn besiedeltes Gebiet mit einer vielfältigen Landschaft. Mit Heidekraut bedeckte Berge, einsame Hochlandmoore bis hin zu den Kiefernwäldern und romantischen Seen (lochs) und Tälern (glens).",
  para2:
    "Besuchen Sie mystische Stätten und bekannte Drehorte. Reisen Sie mit dem Dampfer Sir Walter Scott auf Loch Katrine, zurück in die Vergangenheit. Fahren Sie mit der {link} über den „Harry Potter Viadukt“ zwischen Fort William und Mallaig, wo die Seehunde im Hafen spielen, bevor Sie mit der {link} bis Skye, auf die „Insel des Nebels“ übersetzen.",
  links: [
    { text: "„Jacobite“ Dampfeisenbahn", title: "Das „Jacobite“ Dampfeisenbahn website" },
    { text: "Fähre", title: "Die Calmac Ferry Websitee" },
  ],
  para3:
    "Erkunden Sie die Hebrideninseln mit ihren kristallklaren Küstenwässern und perfekten Sandstränden. Besuchen Sie vielleicht die winzige Insel Iona, Wiege des schottischen Christentums oder machen Sie einen Abstecher auf Islay, die Whiskyinsel, wo neun Destillerien „uisge beatha“ produzieren. Probieren Sie, bevor Sie eine Flasche Single Malt auswählen oder kaufen Sie als Andenken ein hochwertiges Souvenir bei einem lokalen Kunstgewerbler ein.",
  para4: "Schottland hat noch viel mehr zu bieten!",
  reviews: [
    {
      text:
        "Thanks for taking such good care of the German group. The feedback from the group has been extremely positive - it was a relief to have a good guide to rely on",
      by: "German Business Group",
    },
  ],
  CannonKirkAltText: "Kaninchen in einer Wandkanone Kirk Scotland"
};

export default highlands;
