import home from "./home";
import navigation from "./navigation";
import header from "./header";
import languageSelector from "./languageSelector";
import about from "./about";
import services from "./services";
import memberOf from "./memberof";
import highlands from "./highlands";
import edinburgh from "./edinburgh";
import glasgow from "./glasgow";
import contact from "./contact";
import contactForm from "./contactForm";
import carousel from "./carousel";

const translations = {
    navigation: navigation,
    languageSelector: languageSelector,
    header: header,
    home: home,  
    about: about,  
    services: services,
    memberOf: memberOf,
    highlands: highlands,
    edinburgh: edinburgh,
    glasgow: glasgow,
    contact: contact,
    contactForm: contactForm,
    carousel: carousel,
}

export default translations;
