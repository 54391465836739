const contact = {
  title: "Kontakt – Nehmen Sie mit mir Kontakt auf",
  description:
    "Für Anfragen und weitere Informationen darüber, welche Touren ich anbieten kann, kontaktieren Sie mich bitte und ich werde mich bei Ihnen melden. Ich freue mich darauf, von dir zu hören!",
  h1: "Kontakt",
  para1: "Für weitere Information bitte setzen Sie sich mit mir in Verbindung",
  sheepAltText: "Schottische Hochlandschafe, die Gras am Straßenrand essen.",
};

export default contact;
