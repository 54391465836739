import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Switch, Route } from "react-router-dom";
import { useAllRoutes } from "./Utils/router";
import theme from "./theme";
import LoadingContextProvider from "./Utils/LoadingContext";
import SiteLoading from "./Components/Loader/SiteLoading";
import "./i18n";

const Layout = React.lazy(() => import("./Components/Layout"));

function App() {
  const switchRoutes = useAllRoutes();

  const renderLoader = () => <SiteLoading />;

  return (
    <ChakraProvider theme={theme}>
      <LoadingContextProvider>
        <React.Suspense fallback={renderLoader()}>
          <Layout>
            <Switch>
              {switchRoutes.map((r, i) => (
                <Route key={i} path={r.path} component={r.component} exact />
              ))}
            </Switch>
          </Layout>
        </React.Suspense>
      </LoadingContextProvider>
    </ChakraProvider>
  );
}

export default App;
