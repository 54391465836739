const contact = {
  title: "Tours of Scotland Enquires | Contact Details",
  description:
    "For enquires and more information on what tours I can provide please contact me and i shall get back to you. I look forward to hearing from you!",
  h1: "Contact",
  para1: "For further information please contact me.",
  sheepAltText: "Scottish highland sheep eating grass at the side of the road.",
};

export default contact;
