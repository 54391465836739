const memberOf = {
  MemberOfText: "Member of the {link} which is a Member of the World Federation of Tourist Guide Associations(WFTGA) and Member of the {link}(FEG).",
  MemberOfLinkText1: "Scottish Tourist Guides Association",
  MemberOfLinkTitle1: "Marion Rodger Scottish Tourist Guides Association profile.",
  MemberOfLinkText2: "European Federation of Tourist Guide Associations",
  MemberOfLinkTitle2: "The European Federation of Tourist Guide Associations website.",
  GoodToGoAltText: "Good to go Scotland logo",
  STGAAltText: "Scottish Tourist Guides Association logo",
  WFTGAAltText: "World Federation of Tourist Guide Associations logo",
  FEGAltText: "European Federation of Tourist Guide Associations logo",
};

export default memberOf;
