const navigation = {
    home: "Home",
    homeTitle: "Welcome – Marion Rodger, scottish tour guide",
    about: "About",
    aboutTitle: "About Marion Rodger",
    services: "Services",
    servicesTitle: "Scottish tour services",
    escape: "Escape",
    escapeTitle: "Scottish tours",
    highlands: "Highlands & Islands",
    highlandsTitle: "Scottish Highlands & Islands tours",
    edinburgh: "Edinburgh",
    edinburghTitle: "Edinburgh tours",
    glasgow: "Glasgow",
    glasgowTitle: "Glasgow tours",
    contact: "Contact",
    contactTitle: "Contact us about our tours",
}

export default navigation;
